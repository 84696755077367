import React, { useState, useEffect } from "react"

import ListingCard from "./listing-card"
import Paginator from "./listing-paginator"
import Loading from "./loading"

import { onRequest } from "../../util/service"
import withState from "../../util/withState"
import { Link } from "gatsby"

const Listing = ({ endpoint, queryPage, searchParams, state, onDataChange }) => {

  const [data, setData] = useState({
    data: [],
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // loading listings
    setLoading(true)

    onRequest({
      endpoint,
      params: {
        page: queryPage,
        ...searchParams,
      },
      onSuccess: (data) => {
        setData(data)
        onDataChange(data)
      },
      onAnyCase: () => {
        setLoading(false)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, endpoint, queryPage, searchParams])

  const { current_page: currentPage, last_page: lastPage, total } = data

  return (
    <>
      <div className={["container py-5"]} id="jobs-list">
        <div className={["row mt-md-4"]}>
          <div className={["col-lg-4 bg-blue text-white left-panel"]}>
            <div className={["p-4 p-md-5"]}>
              <h2 className={["mb-3 text-center"]}>Working at MAZRUI! </h2>
              <p>Mazrui International is a diversified business group in the UAE, with business interests in Oil &
                Gas,
                Real Estate, Trading, Financial Investments, Education, Construction & Logistics, and Retail. Our
                company
                nurtures future leaders and innovators as we continue to expand globally. Mazrui Group brings endless
                opportunities for career advancement and welcomes people with a heart to serve and a desire to learn.
                <br/><br/>
                We offer attractive opportunities for career development and learning journey.
                <br/><br/>
                We are looking for people who share our core values, who are reliable,
                committed, and respectful.
                <br/><br/>
                Build a career with us and be part of a great place to work!
              </p>
              <div className={["text-center mt-4"]}>
                <h1><strong>JOIN</strong><br/>
                  OUR TEAM</h1>
                <Link
                  to={`/jobs/1`}
                  state={{
                    data,
                    redirectWithData: true,
                  }}
                >
                  <button type="submit" className={["btn btn-light btn-sm mt-3"]}>Upload CV</button>
                </Link>

                <br/>
                <br/>
                <h1>CALL FOR<br/>
                  <strong>INTERNS</strong>
                </h1>
                <Link
                  to={`/jobs/2`}
                  state={{
                    data,
                    redirectWithData: true,
                  }}
                >
                  <button type="submit" className={["btn btn-light btn-sm mt-3"]}>Apply Here</button>
                </Link>
              </div>
            </div>
          </div>
          <div className={["col-lg-8 mt-4 mt-lg-0"]}>
            <div className="row align-items-center listing-container">
              {loading ? (
                <Loading/>
              ) : (<>
                  {!(data || {}).data.length ? (
                      <div className={["row align-items-center"]}>
                        <div className={["col-2 pl-md-5"]}>
                          <img src="/logo-1.png" alt="Mazrui" className="w-100"/>
                        </div>
                        <div className={["col-8"]}>
                          <h3 className={["text-blue mb-2"]}><strong>Unfortunately, at this time, we don't have any job
                            vacancies</strong></h3>
                          <p>Please try sometime later, thank you for visiting Mazrui.Careers<br/>
                          </p>
                        </div>
                        <hr className={["w-100 ml-md-5"]} />
                      </div>
                  ) : null}
                  {((data || {}).data || []).map(sj => (
                    <ListingCard key={sj.id} data={sj || {}} endpoint={endpoint}/>
                  ))}
                </>
              )}
            </div>
            <div className={["row align-items-end pagination"]}>
              <div className={["col-md-8 pl-5 "]}>
                <Paginator
                  currentPage={currentPage}
                  total={total}
                  lastPage={lastPage}
                  next={lastPage > currentPage ? currentPage + 1 : undefined}
                  prev={currentPage === 1 ? undefined : currentPage - 1}
                />
              </div>
              <div className={["col-md-4 text-right"]}>
                {state && typeof state.checkLogin === 'function' && state.checkLogin() === false && (
                  <button type="submit" className="btn btn-primary btn-lg px-4 py-2">
                    <a style={{ color: "white" }} href="/login">Sign In</a>
                  </button>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withState(Listing)
