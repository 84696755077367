import React, { useCallback, useState } from "react"

import SEO from "../components/seo"
import Listing from "../components/partials/listing"
import ListingSearch from "../components/partials/listing-search"
import withState from "../util/withState"

const IndexPage = ({ location, state }) => {
  const getQueryParams = useCallback(() => {
    const paramsBag = new URLSearchParams((location || {}).search)
    const params = {}
    for (const [k, v] of paramsBag.entries()) {
      params[k] = v
    }
    return params
  }, [location])

  const queryPage = (getQueryParams() || {}).page || 1
  const searchQuery = (getQueryParams() || {}).search
  const { apiURL } = state
  const [data, setData] = useState({})
  const [searchParams, setSearchParams] = useState({
    position: searchQuery,
    location: undefined,
    org: undefined,
  })
  const onDataChange = (data) => {
    setData(data)
  }
  return (
    <>
      <SEO title="Job search" keywords={[`mazrui`, `careers`]}/>
      <ListingSearch data={data} searchQuery={searchQuery} onSearch={setSearchParams}/>
      <Listing
        endpoint={`${apiURL}/vacancies` || undefined}
        location={location}
        queryPage={queryPage}
        searchParams={searchParams}
        onDataChange={onDataChange}
      />
    </>
  )
}

export default withState(IndexPage)
