import React from "react"
import { Row, Button } from "react-bootstrap"
import { Link } from "gatsby"
import withState from "../../util/withState"

const ListingItem = ({ data, endpoint, state }) => {
  const { request: position } = data
  if (!data) return null
  return (
    <>
      <Row className="align-items-center job-item">
        <div className="col-2 pl-md-5">
          <img
            src={`https://s3.ap-southeast-1.amazonaws.com/assets.swan.mazrui.ae/Administration/CorporateIdentity/${data.request.h_c_m_organization.swan_orgid}/LOGO.jpg`}
            alt="Mazrui" className="w-100"/>
        </div>
        <div className="col-8">
          <h3 className="text-blue mb-2">
            <strong>
              <Link
                to={`/jobs/${(data || {}).id}`}
                state={{
                  data,
                  redirectWithData: true,
                }}
              >
                {(position || {}).position_requested ||
                (position || {}).position_id_display ||
                "Untitled Position"}
              </Link>
            </strong>
          </h3>
          <p>{(position || {}).hcm_orgid_display || ""}<br/>
            <small>Posted on {(data || {}).date_of_opening}</small>
          </p>
          <p className="mt-1 fs-90">{(data || {}).career_portal_description_teaser}</p>
        </div>
        <div className="col-sm-2 text-right">
          {state.checkLogin() === true ? (
            <Link
              to={`/jobs/${(data || {}).id}`}
              state={{
                data,
                redirectWithData: true,
              }}
            >
              <Button className="btn btn-primary btn-sm" size="sm">
                Apply
              </Button>
            </Link>
          ) : (
            <Link
              to={`/jobs/${(data || {}).id}`}
              state={{
                data,
                redirectWithData: true,
              }}
            >
              <Button className="btn btn-primary btn-sm" size="sm">
                Apply
              </Button>
            </Link>
          )}
        </div>
        <hr className="w-100 ml-md-5"/>
      </Row>
    </>
  )
}

export default withState(ListingItem)
