import React, { useEffect, useState } from "react"
import { Form, Button } from "react-bootstrap"

import withState from "../../util/withState"
import { onRequest } from "../../util/service"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const SearchBar = ({ state, onSearch, data, searchQuery }) => {
  const { state: stateData, setState, apiURL } = state

  const [searchParams, setSearchParams] = useState({
    position: searchQuery,
    location: undefined,
    org: undefined,
  })

  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false)
  useEffect(() => {
    handleSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  useEffect(() => {
    // silently loading organizations
    if (stateData.ometaReady === false) {
      onRequest({
        endpoint: `${apiURL}/ometa/orgs`,
        onSuccess: ometaData => {
          const hcmOrgs = {}
          ;((ometaData || {}).orgs || []).forEach(org => {
            Object.assign(hcmOrgs, { [org.id]: org })
          })
          setState({
            ...{ orgs: hcmOrgs },
            ometaReady: true,
          })
        },
      })
    }
  }, [stateData, apiURL, setState])

  const handleSearchParamsChange = (param, value) => {
    setSearchParams({
      ...searchParams,
      ...{
        [param]: value,
      },
    })
  }

  const handleSearch = () => {
    if (onSearch && onSearch instanceof Function) {
      onSearch(searchParams)
    }
  }
  return (
    <>
      <Form action="#" method="post" className="m-0 p-0" noValidate>
        <div className="bg-grey p-2">
          <div className="container px-md-3  job-search-filters" id="job-filters">
            <div className="row align-items-center pb-3 neg-margin">
              <div className="col-md-6">
                <form>
                  <div className="form-row align-items-center">
                    <div className="col-10 col-md-6">
                      <Form.Control
                        name="search.position"
                        type="text"
                        value={searchParams.position}
                        placeholder="Search Jobs"
                        className="form-control round  mb-2"
                        onChange={e => {
                          handleSearchParamsChange("position", e.target.value)
                        }}
                      />
                    </div>
                    <div className="col-2 col-md-2">
                      <Button
                        color="primary"
                        className="mb-2"
                        onClick={handleSearch}
                      >
                        <FontAwesomeIcon icon={faSearch} className="fas"/>
                      </Button>
                    </div>
                  </div>
                </form>

              </div>
              <div className="col-md-6 text-center text-md-right">
                <Button onClick={() => {
                  handleSearchParamsChange("position", "")
                  handleSearch()
                }} className="btn btn-primary mb-2">View All Jobs</Button>
                <Button onClick={() => {
                  setIsAdvanceSearch(!isAdvanceSearch)
                }} className="btn btn-primary mb-2">Advance Search
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightgrey pt-2 ">
          <div className="container px-md-5">
            <div className="row align-items-center">
              <div className="col-md-7 pb-2">
                <h3><strong>Job Openings</strong> {data.from || "0"} - {data.to || "0"} of {data.total || "0"}</h3>
              </div>
              <div className="col-md-5">
                <div id="advancesearch " style={{ display: isAdvanceSearch ? "block" : "none" }}>
                  <div className="form-row align-items-center">
                    <div className="col-12 col-md-4 text-md-right pb-2">
                      <p>Short by</p>
                    </div>
                    <div className="col-6 col-md-4 pb-2">
                      <Form.Control
                        name="search.location"
                        as="select"
                        className="custom-select mr-sm-2"
                        id="inlineFormCustomSelect"
                        placeholder="Enter Location"
                        onChange={e => {
                          handleSearchParamsChange("location", e.target.value)
                        }}
                      >
                        <option selected value="">Location</option>
                        <option value="1">UAE</option>
                        <option value="2">KSA</option>
                        <option value="3">OMAN</option>
                        <option value="1">EGYPT</option>
                        <option value="2">QATAR</option>
                        <option value="3">BAHRAIN</option>
                        <option value="1">KUWAIT</option>
                        <option value="2">JORDAN</option>
                        <option value="3">ISRAEL</option>
                        <option value="1">IRAQ</option>
                        <option value="2">KAZAKHASTAN</option>
                      </Form.Control>
                    </div>
                    <div className="col-6 col-md-4 pb-2">
                      <Form.Control
                        as="select"
                        name="search.organization"
                        className="custom-select mr-sm-2"
                        id="inlineFormCustomSelect"
                        onChange={e => {
                          handleSearchParamsChange("org", e.target.value)
                        }}
                      >
                        <option value="">Select Organization</option>
                        {Object.keys((stateData || {}).orgs || []).map(orgId => (
                          <option key={orgId} value={orgId}>
                            {stateData.orgs[orgId].name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  )
}

export default withState(SearchBar)
