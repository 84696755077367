import React from "react"
import { Link } from "gatsby"

const Paginator = ({ next, prev, currentPage, lastPage }) => {
  const prevPages = []
  const nextPages = []
  for (let i = 1; i <= 5; i++) {
    let page = currentPage - i
    if (page > 0) {
      prevPages.push(page)
    }
    page = currentPage + i
    if (page <= lastPage) {
      nextPages.push(page)
    }
  }
  return (
    <p>
      <Link to={prev ? `/job-search?page=${prev}#jobs-list` : "/job-search#jobs-list"}>{"<"}</Link> Page
      {prevPages.reverse().map(p =>
        <Link to={`/job-search?page=${p}#jobs-list`}> {p}</Link>,
      )}
      <Link className="font-weight-bold" to="#"> {currentPage}</Link>
      {nextPages.map(p =>
        <Link to={`/job-search?page=${p}#jobs-list`}> {p}</Link>,
      )}
      <Link to={next ? `/job-search?page=${next}#jobs-list` : "/job-search#jobs-list"}>{">"}</Link>
    </p>
  )
}

export default Paginator
